import { useEffect, useState /* , useMemo */ } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Collapse,
  Divider,
  Spin,
  message
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
//import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './rd_details.module.less';
import { useDispatch } from 'react-redux';
import FormItem from 'common/FormItem';
import CustomCollapse from '@/common/CustomCollapse';
//import { debounce } from 'lodash';

import moment from 'moment';
import { history } from 'app/history';

import RD_Timeline_table from '@/common/rd_Timeline_table/RD_Timeline_table';
import RD_Budget_table from '@/common/rd_Budget_table/RD_Budget_table';

import DynamicInputField from './Dynamic_InputField';
import { formatNumberToIndianSystem, hasEmptyValue } from 'utilities/helpers';

const { Option } = Select;
// const { TextArea } = Input;
const { Panel } = Collapse;

const RD_details = ({
  PIF_form_id,
  setSelectedTab,

  createUpdateRDDetail_formLoading,
  createUpdateRDDetail_form,

  completedTabs,
  setCompletedTabs,
  isTabEnabled,
  getFormDetailsByIdLoading,
  getFormDetailsByIdData,
  dropDown_budget_itemsData,
  dropDown_stagesData,
  dropDown_currencyData,
  debounceStages,
  debounceBudget_items,
  debounceCurrency,
  draft_PIF_form_start
}) => {
  if (!completedTabs?.includes(5)) {
    setCompletedTabs([...completedTabs, 5]);
    //console.log('completedTabs::::', completedTabs);
  }
  isTabEnabled(5);

  const storedData = localStorage.getItem('RD_Timeline_table');
  const storedData1 = localStorage.getItem('RD_Budget_table');
  const storedData_bs = localStorage.getItem('RD_Batch_size');

  //console.log('storedData@@:', storedData);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [formData1, setFormData1] = useState({});
  // const [tagSearch, setTagSearch] = useState('');
  const [dataSource, setDataSource] = useState();
  const [dataSource1, setDataSource1] = useState();
  const [fields, setFields] = useState();
  const [totalRow, setTotalRow] = useState(null);
  const [timelineTableError, setTimelineTableError] = useState(false);
  const [budgetTableError, setBudgetTableError] = useState(false);
  const [amountRs, setAmountRs] = useState(0);
  const [provisional_pif, setProvisional_pif] = useState(false);

  useEffect(() => {
    if (
      getFormDetailsByIdData?.fd_info?.pif_type?.units_and_other?.name ===
      'Provisional'
    ) {
      setProvisional_pif(true);
    }
  }, [getFormDetailsByIdData]);

  //console.log('dataSource1:::', dataSource1);
  //const totalRow1 = totalRow['item_id'] = 10;

  useEffect(() => {
    //getFormDetailsByIdData?.rd_detail?.amount_rupee
    if (storedData === 'true' || draft_PIF_form_start === 'true') {
      if (
        formData1?.RD_Budget === undefined &&
        getFormDetailsByIdData?.rd_detail === null
      ) {
        setAmountRs(0);
      } else {
        if (
          formData1?.RD_Budget === undefined &&
          formData1?.currency === undefined
        ) {
          setAmountRs(getFormDetailsByIdData?.rd_detail?.amount_rupee);
        }
        if (formData1?.RD_Budget === undefined && formData1?.currency === 1) {
          setAmountRs(getFormDetailsByIdData?.rd_detail?.budget * 1);
        } else if (
          formData1?.RD_Budget === undefined &&
          formData1?.currency === 2
        ) {
          setAmountRs(getFormDetailsByIdData?.rd_detail?.budget * 83);
        } else if (
          formData1?.RD_Budget === undefined &&
          formData1?.currency === 3
        ) {
          setAmountRs(getFormDetailsByIdData?.rd_detail?.budget * 90);
        }
        if (
          formData1?.currency === undefined &&
          formData1?.RD_Budget !== undefined
        ) {
          if (getFormDetailsByIdData?.rd_detail?.rd_currency?.id === 1) {
            setAmountRs(formData1?.RD_Budget * 1);
          } else if (getFormDetailsByIdData?.rd_detail?.rd_currency?.id === 2) {
            setAmountRs(formData1?.RD_Budget * 83);
          } else if (getFormDetailsByIdData?.rd_detail?.rd_currency?.id === 3) {
            setAmountRs(formData1?.RD_Budget * 90);
          }
        } else if (
          formData1?.currency !== undefined &&
          formData1?.RD_Budget !== undefined
        ) {
          if (formData1?.currency === 1) {
            setAmountRs(formData1?.RD_Budget * 1);
          } else if (formData1?.currency === 2) {
            setAmountRs(formData1?.RD_Budget * 83);
          } else if (formData1?.currency === 3) {
            setAmountRs(formData1?.RD_Budget * 90);
          }
        }
      }
    } else if (storedData === 'false') {
      if (formData1?.currency === 1) {
        setAmountRs(formData1?.RD_Budget * 1);
      } else if (formData1?.currency === 2) {
        setAmountRs(formData1?.RD_Budget * 83);
      } else if (formData1?.currency === 3) {
        setAmountRs(formData1?.RD_Budget * 90);
      }
    }
  }, [
    getFormDetailsByIdData,
    formData1?.RD_Budget,
    formData1?.currency,
    storedData === 'true',
    draft_PIF_form_start
  ]);

  //----RD_Batch_size-------
  function generateKey_bs() {
    return Math.random().toString(36).substr(2, 10);
  }
  useEffect(() => {
    if (
      storedData_bs === 'false' ||
      getFormDetailsByIdData?.rd_detail?.rd_batch_size?.length === 0
    ) {
      setFields([
        {
          key: generateKey_bs(),
          batch_size: ''
          //is_active: true
        }
      ]);
    }
  }, [storedData_bs === 'false']);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.rd_detail?.rd_batch_size?.length > 0 &&
        storedData_bs === 'true') ||
      (getFormDetailsByIdData?.rd_detail?.rd_batch_size?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData = getFormDetailsByIdData?.rd_detail?.rd_batch_size?.map(
        (item) => ({
          key: item?.id?.toString(),
          batch_size: item?.batch_size
          //is_active: item?.//is_active
        })
      );
      setFields(newData);
    }
  }, [getFormDetailsByIdData, storedData_bs === 'true', draft_PIF_form_start]);

  //----RD_Timeline_table-------
  useEffect(() => {
    if (
      storedData === 'false' ||
      getFormDetailsByIdData?.rd_detail?.rd_timelines?.length === 0
    ) {
      setDataSource([
        {
          key: generateKey(),
          stage_id: '',
          date_best_case: moment().format('YYYY-MM-DD'),
          timeline_best_case: '',
          date_base_case: moment().format('YYYY-MM-DD'),
          timeline_base_case: ''
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData === 'false']);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.rd_detail?.rd_timelines?.length > 0 &&
        storedData === 'true') ||
      (getFormDetailsByIdData?.rd_detail?.rd_timelines?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      const newData = getFormDetailsByIdData?.rd_detail?.rd_timelines?.map(
        (item) => ({
          key: item?.id?.toString(),
          // id: item?.id,
          stage_id: item?.stage_id,
          date_best_case: item?.date_best_case,
          timeline_best_case: Number(item?.timeline_best_case),
          date_base_case: item?.date_base_case,
          timeline_base_case: Number(item?.timeline_base_case)
          //is_active: true
        })
      );
      // console.log('newData_RRRRRRRRRRRRR:::', newData);
      setDataSource(newData);
    }
  }, [getFormDetailsByIdData, storedData === 'true', draft_PIF_form_start]);

  function generateKey() {
    return Math.random().toString(36).substr(2, 10);
  }

  //-----RD_Budget_table-------
  useEffect(() => {
    if (
      storedData1 === 'false' ||
      getFormDetailsByIdData?.rd_detail?.rd_budgets?.length === 0
    ) {
      setDataSource1([
        {
          key: generateKey1(),
          item_id: '',
          description: '',
          project_cost_inr_lakhs_us: '',
          project_cost_inr_lakhs_eu: '',
          project_cost_inr_lakhs: '0'
          //is_active: true
        } // Initial empty row
      ]);
    }
  }, [storedData1]);

  useEffect(() => {
    if (
      (getFormDetailsByIdData?.rd_detail?.rd_budgets?.length > 0 &&
        storedData1 === 'true') ||
      (getFormDetailsByIdData?.rd_detail?.rd_budgets?.length > 0 &&
        draft_PIF_form_start === 'true')
    ) {
      let testData1 = getFormDetailsByIdData?.rd_detail?.rd_budgets?.filter(
        (item) => item.item_id !== 20
      );
      // let testData2 = testData1?.filter((item) => item.//is_active !== false);

      const newData = testData1?.map((item) => ({
        key: item?.id?.toString(),
        // id: item?.id,
        item_id: item?.item_id,
        description: item?.description,
        project_cost_inr_lakhs_us: parseFloat(item?.project_cost_inr_lakhs_us),
        project_cost_inr_lakhs_eu: parseFloat(item?.project_cost_inr_lakhs_eu),
        project_cost_inr_lakhs: parseFloat(item?.project_cost_inr_lakhs)
        //is_active: item?.//is_active
      }));

      setDataSource1(newData);

      let totalValData = getFormDetailsByIdData?.rd_detail?.rd_budgets?.filter(
        (item) => item?.item_id === 20
      );
      //console.log('totalValData', totalValData);
      setTotalRow(totalValData);
    }
  }, [getFormDetailsByIdData, storedData1, draft_PIF_form_start]);

  function generateKey1() {
    return Math.random().toString(36).substr(2, 10);
  }

  // function generateKey2() {
  //   return Math.random().toString(36).substr(2, 10);
  // }
  // console.log(
  //   'RRRRRRRR',
  //   parseFloat(getFormDetailsByIdData?.rd_detail?.budget)?.toFixed(2)
  // );
  useEffect(() => {
    form.setFieldsValue({
      RD_Budget:
        storedData === 'true' || draft_PIF_form_start === 'true'
          ? getFormDetailsByIdData?.rd_detail === null
            ? ''
            : parseFloat(getFormDetailsByIdData?.rd_detail?.budget)?.toFixed(2)
          : formData1?.RD_Budget,
      currency: getFormDetailsByIdData?.rd_detail?.rd_currency?.name,
      Stage_Gate: getFormDetailsByIdData?.rd_detail?.stage_gate_poc,
      Amount: getFormDetailsByIdData?.rd_detail?.amount_rupee,
      RD_Timeline: getFormDetailsByIdData?.rd_detail?.rd_timeline
        ? Number(getFormDetailsByIdData?.rd_detail?.rd_timeline)
        : null,
      Stage_gate_milestone:
        getFormDetailsByIdData?.rd_detail?.stage_gate_milestone,
      Remarks: getFormDetailsByIdData?.rd_detail?.rd_remarks
    });
  }, [getFormDetailsByIdData]);

  useEffect(() => {
    form.setFieldsValue({
      initiation_date: moment() || ''
    });
  });
  // useEffect(() => {
  //   const payload = {};
  //   if (tagSearch) payload['search'] = tagSearch;
  //   dispatch(getCategoryList(payload));
  // }, [tagSearch]);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (tagSearch) => {
  //     setTagSearch(tagSearch);
  //   };
  //   return debounce(loadOptions, 400);
  // }, []);

  const handleFormDataOnChange = (slug, value) => {
    //console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  // const isactiveArray = dataSource?.map((obj) => ({ ...obj, //is_active: true }));
  // console.log('isactiveArray::::', isactiveArray);

  const onFinish = () => {
    if (
      dataSource.length < 1 ||
      hasEmptyValue(dataSource, ['timeline_best_case', 'timeline_base_case'])
    ) {
      message.error('R&D Timeline table must have at least 1 entry');
      setTimelineTableError(true);
      return;
    } else if (dataSource1.length < 1 || hasEmptyValue(dataSource1)) {
      setTimelineTableError(false);
      message.error('R&D Budget table must have at least 1 entry');
      setBudgetTableError(true);
      return;
    } else {
      setTimelineTableError(false);
      setBudgetTableError(false);
      // dataSource1.push(totalRow);
      const payload = {
        edit_type: 'draft',
        pif_id: PIF_form_id,
        budget:
          formData1?.RD_Budget === undefined
            ? getFormDetailsByIdData?.rd_detail?.budget
            : formData1?.RD_Budget,
        currency_id:
          formData1?.currency === undefined
            ? getFormDetailsByIdData?.rd_detail?.rd_currency?.id
            : formData1?.currency,
        stage_gate_poc:
          formData1?.Stage_Gate === undefined
            ? getFormDetailsByIdData?.rd_detail?.stage_gate_poc
            : formData1?.Stage_Gate,
        amount_rupee: amountRs,
        // formData1?.Amount === undefined
        //   ? getFormDetailsByIdData?.rd_detail?.amount_rupee
        //   : formData1?.Amount,
        rd_timeline:
          formData1?.RD_Timeline === undefined
            ? getFormDetailsByIdData?.rd_detail?.rd_timeline
            : formData1?.RD_Timeline,
        stage_gate_milestone:
          formData1?.Stage_gate_milestone === undefined
            ? getFormDetailsByIdData?.rd_detail?.stage_gate_milestone
            : formData1?.Stage_gate_milestone,
        rd_remarks:
          formData1?.Remarks === undefined
            ? getFormDetailsByIdData?.rd_detail?.rd_remarks
            : formData1?.Remarks,
        rd_batch_size_list: fields?.map((obj) => ({
          ...obj
        })),
        rd_timelines_list: dataSource?.map((obj) => ({
          ...obj
        })),

        rd_budgets_list: (() => {
          let data = [];
          if (Array.isArray(totalRow)) {
            data = [...dataSource1, ...totalRow];
          } else {
            data = [...dataSource1, totalRow];
          }
          return data?.length === 1
            ? []
            : data?.filter((obj) => obj && obj?.item_id !== '');
        })()
        // totalRow?.project_cost_inr_lakhs > 0 &&
        // totalRow?.project_cost_inr_lakhs_us > 0 &&
        // totalRow?.project_cost_inr_lakhs_eu > 0
        //   ? dataSource1?.map((obj) => ({
        //       ...obj
        //     }))
        //   : []
      };
      console.log('RD_payload::::', payload);
      dispatch(createUpdateRDDetail_form({ payload }))?.then(function (
        response
      ) {
        if (response?.payload?.success) {
          //history.push(`/admin-quizzes`);
          localStorage.setItem('RD_Timeline_table', true);
          localStorage.setItem('RD_Budget_table', true);
          localStorage.setItem('RD_Batch_size', true);
          setSelectedTab(6);
        } else {
          console.log('response?.payload?.error', response?.payload?.error);
        }
      });
    }
  };
  //const defaultDate = moment().format('MMM DD, YYYY');
  return (
    <div className={`${styles.page_container}`}>
      <Spin spinning={getFormDetailsByIdLoading}>
        <div className={`${styles.page_title}`}>
          <span className={styles.title_label}>R&D Details</span>
        </div>

        <Form
          form={form}
          name="PIF_RD_Information"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          {/* <Row gutter={16} className={`${styles.row_1}`}>
            <Col xs={24} sm={12}>
              <FormItem
                label="R&D Budget"
                name="RD_Budget"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  type="number"
                  step="0.01"
                  // min={0}
                  allowClear
                  placeholder="Enter R&D budget "
                  onChange={(e) =>
                    handleFormDataOnChange('RD_Budget', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                // className={`${styles.select_styles}`}
                className={`${styles.input_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceCurrency}
                  placeholder="Select currency "
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('currency', value)
                  }
                >
                  {dropDown_currencyData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          {provisional_pif === true && (
            <Col xs={24} sm={12}>
              <FormItem
                label="Stage Gate (POC)"
                name="Stage_Gate"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please enter stage gate (POC)"
                  onChange={(e) =>
                    handleFormDataOnChange('Stage_Gate', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          <Col span={provisional_pif === true ? 7 : 14} xs={24} sm={12}>
            <FormItem
              label="Amount (₹)"
              //name="Amount"
              rules={[
                {
                  required: false,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={formatNumberToIndianSystem(parseFloat(amountRs))} //{parseFloat(amountRs)?.toFixed(2)}
                // type="number"
                allowClear
                placeholder="Enter amount"
                onChange={(e) =>
                  handleFormDataOnChange('Amount', e.target.value)
                }
              />
            </FormItem>
          </Col>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col span={provisional_pif === true ? 8 : 24} xs={24} sm={12}>
              <FormItem
                label="R&D Timeline (In Months)"
                name="RD_Timeline"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  type="number"
                  // step="0.01"
                  min={0}
                  allowClear
                  placeholder="Enter R&D timeline"
                  onChange={(e) =>
                    handleFormDataOnChange('RD_Timeline', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            {provisional_pif === true && (
              <Col xs={24} sm={12}>
                <FormItem
                  label="Stage Gate Milestone"
                  name="Stage_gate_milestone"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Please enter stage gate milestone"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'Stage_gate_milestone',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row> */}
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col xs={24} sm={7}>
              <FormItem
                label="R&D Budget"
                name="RD_Budget"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  type="number"
                  step="0.01"
                  // min={0}
                  allowClear
                  placeholder="Enter R&D budget "
                  onChange={(e) =>
                    handleFormDataOnChange('RD_Budget', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={3}>
              <FormItem
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                // className={`${styles.select_styles}`}
                className={`${styles.input_styles}`}
              >
                <Select
                  showSearch
                  onSearch={debounceCurrency}
                  placeholder="Select currency "
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onChange={(value) =>
                    handleFormDataOnChange('currency', value)
                  }
                >
                  {dropDown_currencyData?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            {provisional_pif === true && (
              <Col xs={24} sm={7}>
                <FormItem
                  label="Stage Gate (POC)"
                  name="Stage_Gate"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Please enter stage gate (POC)"
                    onChange={(e) =>
                      handleFormDataOnChange('Stage_Gate', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            )}
            <Col
              span={provisional_pif ? 7 : 14}
              xs={24}
              sm={provisional_pif ? 7 : 14}
            >
              <FormItem
                label="Amount (₹)"
                //name="Amount"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={formatNumberToIndianSystem(parseFloat(amountRs))} //{parseFloat(amountRs)?.toFixed(2)}
                  // type="number"
                  allowClear
                  placeholder="Enter amount"
                  onChange={(e) =>
                    handleFormDataOnChange('Amount', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.row_1}`}>
            <Col xs={24} sm={12}>
              <FormItem
                label="R&D Timeline (In Months)"
                name="RD_Timeline"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  type="number"
                  // step="0.01"
                  //min={0}
                  allowClear
                  placeholder="Enter R&D timeline"
                  onChange={(e) =>
                    handleFormDataOnChange('RD_Timeline', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            {provisional_pif === true && (
              <Col span={16} xs={24} sm={12}>
                <FormItem
                  label="Stage Gate Milestone"
                  name="Stage_gate_milestone"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <Input
                    allowClear
                    placeholder="Please enter stage gate milestone"
                    onChange={(e) =>
                      handleFormDataOnChange(
                        'Stage_gate_milestone',
                        e.target.value
                      )
                    }
                  />
                </FormItem>
              </Col>
            )}
          </Row>
          <div className={styles.customWidthWrapper}>
            <DynamicInputField
              fields={fields}
              setFields={setFields}
              generateKey_bs={generateKey_bs}
              storedData_bs={storedData_bs}
            />
          </div>

          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
              style={
                timelineTableError
                  ? {
                      border: '1px solid red',
                      backgroundColor: '#f546460d',
                      borderRadius: '0.5rem'
                    }
                  : {}
              }
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    R&D Timeline{' '}
                    <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <RD_Timeline_table
                    setDataSource={setDataSource}
                    dataSource={dataSource}
                    setError={setTimelineTableError}
                    generateKey={generateKey}
                    storedData={storedData}
                    dropDown_stagesData={dropDown_stagesData}
                    debounceStages={debounceStages}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>
          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
              style={
                budgetTableError
                  ? {
                      border: '1px solid red',
                      backgroundColor: '#f546460d',
                      borderRadius: '0.5rem'
                    }
                  : {}
              }
            >
              <Panel
                header={
                  <span style={{ fontWeight: 500, fontSize: '14px' }}>
                    R&D Budget{' '}
                    <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                  </span>
                }
                //extra="Add email message"
                key="2"
              >
                <Divider style={{ marginTop: '0px' }} />
                <div className={styles.scrollableContainer}>
                  <RD_Budget_table
                    setDataSource1={setDataSource1}
                    dataSource1={dataSource1}
                    setError={setBudgetTableError}
                    generateKey1={generateKey1}
                    storedData1={storedData1}
                    dropDown_budget_itemsData={dropDown_budget_itemsData}
                    totalRow={totalRow}
                    setTotalRow={setTotalRow}
                    getFormDetailsByIdData={getFormDetailsByIdData}
                    debounceBudget_items={debounceBudget_items}
                  />
                </div>
              </Panel>
            </CustomCollapse>
          </Row>

          <Row gutter={16} className={`${styles.ip_table_container}`}>
            <Col span={24}>
              <FormItem
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter remarks"
                  onChange={(e) =>
                    handleFormDataOnChange('Remarks', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/home`)}
            >
              Cancel
            </CustomButton>
            <FormItem>
              <CustomButton
                loading={createUpdateRDDetail_formLoading}
                type="primary"
                htmlType="submit"
                className={styles.post_btn_style}
              >
                Next
              </CustomButton>
            </FormItem>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default RD_details;
